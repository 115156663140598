import React, { ReactElement } from 'react'

import { t } from 'i18n'

export interface FormGroupProps {
  inputId?: string
  label?: string | React.ReactNode
  className?: string
  inputClassName?: string
  vertical?: boolean
  required?: boolean
  dataContains?: string
  errors?: string[]
  hideFormGroup?: boolean
}

const FormGroup: React.FC<FormGroupProps> = ({
  inputId = '',
  label,
  className = '',
  vertical = false,
  required = false,
  dataContains = null,
  errors = [],
  hideFormGroup = false,
  children
}) => {
  const hasErrors = errors && errors.length > 0

  if (hideFormGroup) {
    return children as ReactElement<any, any>
  }

  return (
    <div
      className={`${className} form-group ${vertical || 'row'} ${hasErrors && 'has-danger'}`}
      data-contains={dataContains}
    >
      <label className={`${vertical || 'col-sm-3 col-form-label'} ${required && 'font-weight-bold'}`} htmlFor={inputId}>
        {required && (
          <abbr className='text-danger' title={t('simple_form.required.text')}>
            {t('simple_form.required.mark')}
          </abbr>
        )}
        {label}
      </label>
      <div className={`${vertical || 'col-sm-9'} children`}>{children}</div>
      {hasErrors && <div className='col-sm-12 form-control-feedback'>{errors.join('; ')}</div>}
    </div>
  )
}

export default FormGroup
